
import { defineComponent, reactive, PropType } from 'vue'
import InputText from 'primevue/inputtext'
import useVuelidate from '@vuelidate/core'
import { email, minLength, numeric } from '@vuelidate/validators'
import { IContactInfo } from '@/models/jobBank/interfaces/company'

export default defineComponent({
  components: {
    InputText
  },
  props: {
    contactsData: {
      type: Object as PropType<IContactInfo>
    },
    isEditing: {
      type: Boolean
    }
  },
  setup (props, { emit }) {
    const formData = reactive({
      name: '',
      lastName: '',
      position: '',
      _id: null
    })
    const state = reactive({
      email: '',
      phone: undefined
    })
    if (props.contactsData) {
      const newFormData = reactive({
        _id: props.contactsData?._id as string,
        name: props.contactsData?.name,
        lastName: props.contactsData?.lastName,
        position: props.contactsData?.position
      })
      const newState = reactive({
        email: props.contactsData?.email,
        phone: props.contactsData?.phone
      })
      Object.assign(state, newState)
      Object.assign(formData, newFormData)
    }

    const rules = {
      email: { email },
      phone: { minLengthValue: minLength(8), numeric }
    }
    const v$ = useVuelidate(rules, state)

    const submit = async () => {
      v$.value.$touch()
      const isFormCorrect = await v$.value.$validate()
      const { email, phone } = v$.value
      if (isFormCorrect) {
        return { email: email.$model, phone: phone.$model, name: formData.name, lastName: formData.lastName, position: formData.position, _id: formData._id }
      }
      return false
    }

    return {
      state,
      v$,
      submit,
      formData
    }
  }
})
