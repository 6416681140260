
import { defineComponent, ref, Ref } from 'vue'
import Button from 'primevue/button'
import sContactInfoForm from '../children/components/SContactInfoForm.vue'
import { IContactInfo } from '@/models/jobBank/interfaces/company'

export default defineComponent({
  components: {
    Button,
    sContactInfoForm
  },
  props: {
    formData: {
      type: Object
    },
    isEditing: {
      type: Boolean
    }
  },
  emits: ['nextPage', 'prevPage', 'cancel'],
  setup (props, { emit }) {
    const contactsData = ref(props.formData?.contacts)
    const contactForms: Ref<any>[] = []
    const formBase: Ref<any> = ref(null)
    const next = async () => {
      let shouldContinue = true
      let allForms : any = []
      const submitBaseForm = await (formBase.value as any).submit()
      if (submitBaseForm) {
        allForms.push(submitBaseForm)
        for (const form of contactForms) {
          const submit = await (form as any).submit()
          if (submit) {
            if (submit._id !== '') {
              allForms.push(submit)
            } else {
              delete submit._id
              allForms.push(submit)
            }
          } else {
            allForms = []
            shouldContinue = false
            break
          }
        }
      } else {
        allForms = []
        shouldContinue = false
      }
      if (shouldContinue) {
        const formsWithCompanyId = allForms.map((contact: IContactInfo) => { return { ...contact, companyId: props.formData?._id } })
        emit('nextPage', { formData: { contacts: formsWithCompanyId }, pageIndex: 1 })
      }
    }
    const back = () => { emit('prevPage', { pageIndex: 1 }) }
    const cancel = () => { emit('cancel') }

    const forms: Ref<number[]> = ref([])

    let counter = 1
    if (props.isEditing) {
      for (const contact in props.formData?.contacts) {
        forms.value.push(parseInt(contact))
      }
      forms.value.pop()
    }
    const addContact = () => {
      counter += 1
      forms.value.push(counter)
    }

    return {
      addContact,
      forms,
      cancel,
      back,
      next,
      contactForms,
      formBase,
      contactsData
    }
  }
})
